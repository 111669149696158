.container {
  height: 80vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.inputContainer h1 {
  font-family: 'Playfair Display', serif;
  font-size: 3.5rem;
  color: #fff;
  margin-bottom: 1.5rem;
}
.inputContainer h1 span {
  color: var(--primary-color);
}
.inputContainer div {
  display: flex;
  margin-bottom: 2rem;
}
.inputContainer input {
  width: 100%;
  padding: 0.5rem 0.2rem 0.5rem 0;
  text-indent: 1rem;
  border: none;
  outline: none;
  font-size: 1rem;
  border-top-left-radius: 3px;
  border-bottom-left-radius: 3px;
}
.inputContainer input::placeholder {
  font-size: 0.9rem;
}
.inputContainer button {
  padding: 0 1rem;
  height: 3rem;
  font-size: 0.8rem;
  text-transform: uppercase;
  font-weight: bold;
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;
  color: #fff;
  background-color: var(--primary-color);
  border: none;
  cursor: pointer;
}

/* Result */
.result {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem 1rem;
}
.result p {
  color: #fff;
  border: 1px solid var(--primary-color);
  padding: 0.5rem 1rem;
  margin-right: 1rem;
}
.result button {
  padding: 0.5rem 1rem;
  color: #fff;
  cursor: pointer;
  background: var(--primary-color);
  border: none;
}
.result button.copied {
  background: #fff;
  color: var(--primary-color);
}
.noData {
  color: #fff;
  font-size: 1rem;
}

/* background animate */
@keyframes animate {
  0%{
      transform: translateY(0) rotate(0deg);
      opacity: 1;
      border-radius: 0;
  }
  100%{
      transform: translateY(-1000px) rotate(720deg);
      opacity: 0;
      border-radius: 50%;
  }
}

.background {
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  margin: 0;
  padding: 0;
  background: #4e54c8;
  overflow: hidden;
  z-index: -1;
}
.background li {
  position: absolute;
  display: block;
  list-style: none;
  width: 20px;
  height: 20px;
  background: rgba(255, 255, 255, 0.2);
  animation: animate 19s linear infinite;
}




.background li:nth-child(0) {
  left: 65%;
  width: 97px;
  height: 97px;
  bottom: -97px;
  animation-delay: 1s;
}
.background li:nth-child(1) {
  left: 7%;
  width: 95px;
  height: 95px;
  bottom: -95px;
  animation-delay: 2s;
}
.background li:nth-child(2) {
  left: 41%;
  width: 135px;
  height: 135px;
  bottom: -135px;
  animation-delay: 4s;
}
.background li:nth-child(3) {
  left: 36%;
  width: 111px;
  height: 111px;
  bottom: -111px;
  animation-delay: 3s;
}
.background li:nth-child(4) {
  left: 35%;
  width: 140px;
  height: 140px;
  bottom: -140px;
  animation-delay: 3s;
}
.background li:nth-child(5) {
  left: 60%;
  width: 99px;
  height: 99px;
  bottom: -99px;
  animation-delay: 11s;
}
.background li:nth-child(6) {
  left: 74%;
  width: 104px;
  height: 104px;
  bottom: -104px;
  animation-delay: 14s;
}
.background li:nth-child(7) {
  left: 79%;
  width: 105px;
  height: 105px;
  bottom: -105px;
  animation-delay: 3s;
}
.background li:nth-child(8) {
  left: 51%;
  width: 79px;
  height: 79px;
  bottom: -79px;
  animation-delay: 6s;
}
.background li:nth-child(9) {
  left: 69%;
  width: 114px;
  height: 114px;
  bottom: -114px;
  animation-delay: 17s;
}
.background li:nth-child(10) {
  left: 71%;
  width: 84px;
  height: 84px;
  bottom: -84px;
  animation-delay: 13s;
}
.background li:nth-child(11) {
  left: 89%;
  width: 132px;
  height: 132px;
  bottom: -132px;
  animation-delay: 34s;
}
.background li:nth-child(12) {
  left: 2%;
  width: 98px;
  height: 98px;
  bottom: -98px;
  animation-delay: 6s;
}